import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { isMobiSkin } from '../../../scripts/applicationsSkinSetter';
import { RentaDomains } from '../../models/enums';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  /** All languages in app */
  public readonly languages: TranslationsLanguage[] = [
    { short: 'en', long: 'English', apiLong: 'ENGLISH', translationKey: 'LANGUAGE.ENGLISH', available: true },
    { short: 'nl', long: 'Nederlands', apiLong: 'DUTCH', translationKey: 'LANGUAGE.DUTCH', available: true },
    { short: 'fr', long: 'Français', apiLong: 'FRENCH', translationKey: 'LANGUAGE.FRENCH', available: true },
    { short: 'de', long: 'Deutsch', apiLong: 'GERMAN', translationKey: 'LANGUAGE.GERMAN', available: true },
  ];
  public readonly _currentLang: WritableSignal<string>;
  public readonly currentLang: Signal<string>;
  public readonly currentLangLong: Signal<string>;

  /** @ignore */
  private readonly initialDefaultLanguage = 'en';

  public constructor(private readonly ngxTranslate: TranslateService) {
    this._currentLang = signal(this.ngxTranslate.currentLang);
    this.currentLang = computed(this._currentLang);
    this.currentLangLong = computed(
      () => this.languages.find((language) => language.short === this._currentLang())!.long,
    );
    this.ngxTranslate.onLangChange.subscribe((event) => {
      this._currentLang.set(event.lang);
    });
  }

  /** @ignore */
  private get defaultLang(): string {
    return this.ngxTranslate.defaultLang;
  }

  /** Method to switch the current active language
   *
   * The string to pass is a [Language]{@link TranslationsLanguage}'s short notation */
  public useLang(language: string): boolean {
    moment.locale(language);
    const givenLanguageIsUsable = this.isUsableLanguage(language);
    const langToUse = givenLanguageIsUsable ? language : this.defaultLang;
    this.ngxTranslate.use(langToUse);
    const currentDomain = isMobiSkin ? RentaDomains.EUROPE : RentaDomains.RENTA;
    document.cookie = `renta-language=${langToUse};domain=${currentDomain};path=/;Expires=${moment().add(10, 'years').toDate()}`; // update lang cookie
    return givenLanguageIsUsable;
  }

  /** @ignore */
  public getCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let index = 0; index < ca.length; index++) {
      let cat = ca[index] ?? '';
      while (cat.charAt(0) === ' ') {
        cat = cat.substring(1);
      }
      if (cat.indexOf(name) === 0) {
        return cat.substring(name.length, cat.length);
      }
    }
    return '';
  }

  /** Set the current default fallback language
   *
   * The string to pass is a [Language]{@link TranslationsLanguage}'s short notation */
  public setDefaultLanguage(language: string): void {
    this.ngxTranslate.setDefaultLang(this.isUsableLanguage(language) ? language : this.defaultLang);
  }

  /** Programmatic way of getting a translation for given translation-key
   *
   * Further information */
  public stream(key: string | Array<string>, interpolateParams?: Object): Object | undefined {
    return this.ngxTranslate.stream(key, interpolateParams);
  }

  /** @ignore */
  public initialize(): void {
    const rentaLanguage = this.getCookie('renta-language');

    this.addLangs();
    this.setDefaultLanguage(rentaLanguage ?? this.initialDefaultLanguage);

    rentaLanguage ? this.useLang(rentaLanguage) : this.useBrowserLang();
  }

  /** @ignore */
  private isUsableLanguage(language: string): boolean {
    const filteredLanguages = this.languages.filter((lang) => lang.available && lang.short === language);
    return filteredLanguages.length > 0;
  }

  /** @ignore */
  private addLangs(): void {
    const availableLanguages = this.languages.filter((lang) => lang.available).map((lang) => lang.short);
    this.ngxTranslate.addLangs(availableLanguages);
  }

  /** @ignore */
  private useBrowserLang(): void {
    const browserLang = this.ngxTranslate.getBrowserLang();
    this.ngxTranslate.use(browserLang as string);
  }
}

/**
 * Language model used by [TranslationService]{@link TranslationService}
 */
export class TranslationsLanguage {
  /** Short notation ('en', 'nl') */
  public short!: string;
  /** Long native notation ('English', 'Nederlands') */
  public long!: string;
  /** Notation used for API calls ('ENGLISH', 'DUTCH') */
  public apiLong!: string;
  /** Key used for ngx-translation pipe ('LANGUAGE.ENGLISH', 'LANGUAGE.DUTCH') */
  public translationKey!: string;
  /** Whether the language has been implemented in the app */
  public available!: boolean;
}
