
export const ANOMALY_REASONS = [
  'PACKAGE_ORDER_MISSING',
  'DOCUMENTS_MISSING',
  'DOCUMENTS_INCORRECT',
  'DOCUMENTS_CORRUPT',
  'ADDRESS_MISSING',
  'ADDRESS_INCORRECT',
  'ITEMS_MISSING',
  'LICENSE_PLATE_MISSING',
  'LICENSE_PLATE_DAMAGED'
] as const;

export type AnomalyReason = typeof ANOMALY_REASONS[number];

