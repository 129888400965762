<rs-container>
  <left-area>
    <ct-copyright>
      {{ copyrightYear() }}
      <mat-icon fontIcon="copyright" />
      {{ companyName }}
    </ct-copyright>

    <a
      href="http://www.rentasolutions.org/{{ this.currentLang() }}/disclaimer"
      target="_blank"
    >
      {{ disclaimerKey() | translate }}
    </a>

    <a
      href="http://www.rentasolutions.org/{{ this.currentLang() }}/cookies"
      target="_blank"
    >
      {{ cookiesKey() | translate }}
    </a>
  </left-area>

  <rigth-area>
    <button
      [matMenuTriggerFor]="aboveMenu"
      class="rs-btn--text btn-lang-switcher"
      data-testid="footer-lang-switcher-btn"
      mat-button
    >
      {{ currentLangLong() }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>

    <mat-menu
      #aboveMenu="matMenu"
      class="rs-language-menu"
      yPosition="above"
    >
      @for (language of languages; track language) {
      <button
        (click)="setLanguage(language)"
        mat-menu-item
      >
        {{ language.long }}
      </button>
      }
    </mat-menu>

    <a
      [href]="supportLink()"
      class="help"
      data-testid="footer-need-help-btn"
      target="_blank"
    >
      <mat-icon>help_outline</mat-icon>
      Support Center
    </a>
  </rigth-area>
</rs-container>