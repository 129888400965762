<rs-top-bar-menu
  *ngIf="this.shouldShowTopBar$ | async"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment === 'dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
  [menuItems]="this.menuItems$ | async"
  application="MGT"
/>

<rs-loader />

<router-outlet />

<rs-footer />