
export const ANOMALY_STATUSES = ['TODO_LC', 'TODO_STACI', 'RESOLVED', 'CANCELLED'] as const;
export const AnomalyStatusChip: Record<typeof ANOMALY_STATUSES[number], string> = {
  TODO_LC: 'orange',
  TODO_STACI: 'yellow',
  CANCELLED: 'red',
  RESOLVED: 'green',
};

export type AnomalyStatus = typeof ANOMALY_STATUSES[number];
