import { TranslationService } from '../../services/translate/translate.service';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, input, signal } from '@angular/core';
import { isMobiSkin } from '../../../scripts/applicationsSkinSetter';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RentaDomains } from '../../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs';

/**
 * Uses [TranslationService]{@link TranslationService} to control current language
 * and display available languages in dropdown.
 */
@Component({
  selector: 'rs-footer',
  templateUrl: './rs-footer.component.html',
  styleUrls: ['./rs-footer.component.scss'],
  imports: [CommonModule, TranslateModule, MatIconModule, MatMenuModule, MatButtonModule],
  providers: [TranslationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RsFooterComponent {
  /** Translation-key for the "Disclaimer" text displayed,
   * component will automatically translate for given key
   *
   * Provide the translation-key in translation files
   * or override with self-defined translation-key */
  public disclaimerKey = input<string>('FOOTER.DISCLAIMER');
  /** Translation-key for the "Cookies" text displayed,
   * component will automatically translate for given key
   *
   * Provide the translation-key in translation files
   * or override with self-defined translation-key */
  public cookiesKey = input<string>('FOOTER.COOKIES');
  /** Translation-key for the "Need help?" text displayed,
   * component will automatically translate for given key
   *
   * Provide the translation-key in translation files
   * or override with self-defined translation-key */
  public needHelpKey = input<string>('FOOTER.NEED_HELP');

  public copyrightYear = signal<number>(new Date().getFullYear());
  public companyName?: string = isMobiSkin ? RentaDomains.EUROPE_COPY : RentaDomains.RENTA_COPY;
  protected readonly supportLinkDomain = isMobiSkin
    ? 'https://support.mobeyond.eu'
    : 'https://support.rentasolutions.org';
  protected readonly supportLink = signal<string>(this.supportLinkDomain);

  protected readonly currentLang = inject(TranslationService).currentLang;
  protected readonly currentLangLong = inject(TranslationService).currentLangLong;

  protected readonly languages = inject(TranslationService).languages;

  public constructor(
    private readonly translate: TranslationService,
    private readonly ngxTranslateService: TranslateService,
  ) {
    this.ngxTranslateService.onLangChange
      .pipe(startWith({ lang: this.ngxTranslateService.currentLang }), takeUntilDestroyed())
      .subscribe((lang) => {
        this.supportLink.set(`${this.supportLinkDomain}/${lang.lang === 'de' ? 'en' : lang.lang}/support/home`);
      });
  }

  /** @ignore */
  public setLanguage(language: { long: string; short: string }): void {
    this.translate.useLang(language.short);
  }
}
